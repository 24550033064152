*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

h1 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

h3 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

h4 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

h5 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

h6 {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

em {
  font-style: italic;
}

input::-webkit-datetime-edit {
  margin-bottom: -2px;
  padding: 0;
  line-height: 1;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.plan-form-grid {
  grid-template: "name name"
                 "time time"
                 "next-chapter next-chapter"
                 "translation translation"
                 "chapters chapters"
                 "email-reminders text-reminders"
                 "actions actions"
                 / 1fr 1fr;
  display: grid;
}

@media (width >= 640px) {
  .plan-form-grid {
    grid-template: "name time"
                   "chapters chapters"
                   "next-chapter translation"
                   "email-reminders text-reminders"
                   "actions actions"
                   / 1fr 1fr;
  }
}

@media (width >= 768px) {
  .plan-form-grid {
    grid-template: "name name name name time time"
                   "next-chapter next-chapter translation translation chapters chapters"
                   "email-reminders email-reminders text-reminders text-reminders actions actions"
                   / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.plan-name {
  grid-area: name;
}

.plan-time {
  grid-area: time;
}

.plan-email {
  grid-area: email;
}

.plan-next-chapter {
  grid-area: next-chapter;
}

.plan-translation {
  grid-area: translation;
}

.plan-chapters {
  grid-area: chapters;
}

.plan-email-reminders {
  grid-area: email-reminders;
}

.plan-text-reminders {
  grid-area: text-reminders;
}

.plan-actions {
  grid-area: actions;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.\!bottom-20 {
  bottom: 5rem !important;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-\[999999\] {
  z-index: 999999;
}

.z-\[999\] {
  z-index: 999;
}

.-order-1 {
  order: -1;
}

.m-1 {
  margin: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.\!h-contentD {
  height: calc(100dvh - 3rem) !important;
}

.h-100svh {
  height: 100svh;
}

.h-16 {
  height: 4rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-\[3px\] {
  height: 3px;
}

.h-content {
  height: calc(100vh - 3rem);
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.max-h-\[95vh\] {
  max-height: 95vh;
}

.max-h-content {
  max-height: calc(100vh - 3rem);
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-16 {
  min-height: 4rem;
}

.w-0 {
  width: 0;
}

.w-11 {
  width: 2.75rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[200px\] {
  width: 200px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-\[95vw\] {
  max-width: 95vw;
}

.max-w-screen {
  max-width: 100vw;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[20\%\] {
  --tw-translate-y: 20%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.touch-none {
  touch-action: none;
}

.select-none {
  user-select: none;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-2 {
  gap: .5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-primary-300 {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-900\/70 {
  background-color: #111827b3;
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.bg-primary-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.bg-primary-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.bg-primary-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.fill-current {
  fill: currentColor;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-4 {
  line-height: 1rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

.text-primary-700 {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.text-primary-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.text-primary-950 {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.placeholder-primary-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(190 242 100 / var(--tw-placeholder-opacity));
}

.placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 165 165 / var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_1px_5px\] {
  --tw-shadow: 0 1px 5px;
  --tw-shadow-colored: 0 1px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_10px_38px_-10px_rgba\(22\,_23\,_24\,_0\.35\)\,0px_10px_20px_-15px_rgba\(22\,_23\,_24\,_0\.2\)\] {
  --tw-shadow: 0px 10px 38px -10px #16171859, 0px 10px 20px -15px #16171833;
  --tw-shadow-colored: 0px 10px 38px -10px var(--tw-shadow-color), 0px 10px 20px -15px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f, 0 -1px 4px -1px #0000000f;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color), 0 -1px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-primary-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity));
}

.ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-\[1500ms\] {
  transition-delay: 1.5s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.duration-\[2000ms\] {
  transition-duration: 2s;
}

.ease-\[cubic-bezier\(0\.3\,2\,0\.21\,0\.85\)\] {
  transition-timing-function: cubic-bezier(.3, 2, .21, .85);
}

.ease-\[cubic-bezier\(0\.79\,0\,0\.6\,-0\.3\)\] {
  transition-timing-function: cubic-bezier(.79, 0, .6, -.3);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.\[color\:var\(--8thday-center-color\,var\(--8thday-eight-color\,var\(--8thday-petal-color\,currentColor\)\)\)\] {
  color: var(--8thday-center-color, var(--8thday-eight-color, var(--8thday-petal-color, currentColor)));
}

.\[color\:var\(--8thday-eight-color\,var\(--8thday-petal-color\,currentColor\)\)\] {
  color: var(--8thday-eight-color, var(--8thday-petal-color, currentColor));
}

.\[color\:var\(--8thday-leaf-color\,currentColor\)\] {
  color: var(--8thday-leaf-color, currentColor);
}

.\[color\:var\(--8thday-petal-color\,currentColor\)\] {
  color: var(--8thday-petal-color, currentColor);
}

.esv-text {
  font-family: Georgia, Times New Roman, Times, serif;
  display: inline;
}

.esv-text h2 {
  color: #b36c38;
  margin-bottom: 5px;
  font-size: 1.15em;
  font-style: italic;
  font-weight: bold;
}

.esv-text h3 {
  color: #b36c38;
  margin-top: 1.25em;
  margin-bottom: .75em;
  font-size: 1.1em;
  font-style: italic;
  font-weight: 300;
}

.esv-text h3.psalm-book {
  color: #000;
  letter-spacing: .2em;
  text-transform: uppercase;
  padding-left: 4em;
  font-family: serif;
  font-style: normal;
}

.esv-text p {
  margin-bottom: 0;
  font-size: 108%;
  line-height: 130%;
}

.esv-text h2#top {
  border: none;
  padding: 0;
}

div#esv_text #reading, div#esv_text .devotion-date {
  color: #b36c38;
  margin-top: 15px;
  font-size: 1.15em;
  font-style: normal;
  font-weight: bold;
}

.esv-text h2.book-name {
  color: #393939;
  font-variant: small-caps;
  border-bottom: 1px solid;
  margin-left: 0;
  padding: 1em 0 .7em;
  font-family: Times New Roman, serif;
  font-size: 2em;
  font-style: normal;
  font-weight: normal;
}

.esv-text span.indent {
  padding-left: 2em;
  display: inline-block;
}

.esv-text p {
  text-indent: 1.5em;
  color: #363030;
}

.esv-text p.same-paragraph {
  text-indent: 0;
}

.esv-text span.end-line-group, .esv-text span.begin-line-group {
  height: 1em;
  display: block;
}

.esv-text h3 + span.begin-line-group, .esv-text h4 + span.begin-line-group, .esv-text span.end-line-group + span.begin-line-group {
  display: none;
}

.esv-text p.line {
  text-indent: -3.5em;
  clear: both;
  margin: 0;
  padding-left: 5.5em;
}

.esv-text p.indent {
  text-indent: -4em;
  padding-left: 7em;
}

.esv-text p.indent-2, .esv-text p.psalm-doxology {
  text-indent: -5em;
  padding-left: 9em;
}

.esv-text p.declares {
  text-indent: -2em;
  padding-left: 11em;
}

span.small-caps, span.divine-name {
  font-variant: small-caps;
}

.esv-text span.selah {
  text-align: right;
  margin-left: 1em;
  margin-right: 1em;
  font-style: italic;
}

.esv-text p.extra-space {
  margin-top: 1em;
}

.esv-text h4 {
  padding-left: 2.5em;
  font-weight: normal;
}

.esv-text h4.speaker {
  font-variant: small-caps;
  text-align: center;
  width: 10em;
  margin-bottom: 0;
  padding-left: 5em;
  font-size: .9em;
}

.esv-text h4.textual-note {
  font-variant: small-caps;
  margin-top: .4em;
  font-size: .9em;
}

.esv-text h4.psalm-acrostic-title {
  font-variant: small-caps;
  text-align: center;
  width: 10em;
  margin-bottom: 0;
  padding-left: 5em;
  font-size: .9em;
}

.esv-text h4.psalm-title {
  font-variant: small-caps;
  margin-bottom: .3em;
  font-size: .9em;
  line-height: 1.2em;
  position: relative;
}

.esv-text span.footnote {
  vertical-align: top;
  padding-left: .1em;
  padding-right: .3em;
  font-size: .7em;
}

.esv-text a.cf {
  color: #6e92ac;
  cursor: pointer;
  vertical-align: top;
  padding-left: .1em;
  padding-right: .15em;
  font-size: .7em;
  font-weight: bold;
  text-decoration: none;
}

.esv-text a.cf:hover {
  color: #1d5a7e;
  text-decoration: none;
}

.esv-text a.fn {
  color: #6e92ac;
  vertical-align: top;
  font-family: serif;
  font-weight: bold;
  text-decoration: none;
}

.esv-text a.fn:hover {
  color: #1d5a7e;
  cursor: pointer;
  text-decoration: none;
}

.esv-text .footnotes h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

.esv-text .footnotes p {
  text-indent: 0;
  font-size: 90%;
  line-height: 120%;
}

.esv-text span.footnote-ref {
  font-weight: bold;
}

.esv-text p.copyright {
  text-indent: 0;
}

.esv-text object.audio {
  margin: 0 0 0 10px;
  padding: 0;
}

.esv-text p.starts-chapter, .esv-text p.john8 {
  text-indent: 0;
}

.esv-text ul {
  width: auto;
}

.esv-text ul.surrounding-chapters li {
  list-style-type: none;
  display: inline;
}

.esv-text span.chapter-num {
  color: #b4b4b4;
  float: left;
  text-indent: 0;
  margin: 15px -.1em 0 0;
  font-family: serif;
  font-size: 3em;
  position: relative;
}

.esv-text p.line span.chapter-num {
  text-indent: 0;
  text-align: center;
  width: 55px;
  margin-top: -4px;
  font-size: 2.3em;
  position: absolute;
  top: auto;
  left: 0;
}

.esv-text span.verse-num {
  color: #b36c38;
  vertical-align: top;
  padding-right: .15em;
  font-family: Arial, Helvetica, sans-serif;
  font-size: .7em;
  font-weight: bold;
}

.esv-text p.line span.verse-num {
  padding-left: 0;
}

.esv-text p.line .verse-num {
  text-indent: 0;
  position: absolute;
  left: 20px;
}

.esv-text p.virtual {
  text-indent: 0;
  display: inline;
}

.esv-text p.line p.virtual {
  text-indent: 0;
  margin-left: 0;
  padding-left: 2.5em;
  display: block;
}

.esv-text ul.surrounding-chapters li:before {
  content: "| ";
}

.esv-text ul.surrounding-chapters li:first-child:before {
  content: "Â« ";
}

.esv-text ul.surrounding-chapters li:last-child:after {
  content: " Â»";
}

.esv-text ul.surrounding-days {
  padding-left: 0;
}

.esv-text ul.surrounding-days li {
  list-style-type: none;
  display: inline;
}

.esv-text ul.surrounding-days:before {
  content: "Â« ";
}

.esv-text ul.surrounding-days li:last-child:after {
  content: " Â»";
}

.esv-text ul.surrounding-days li:last-child:before {
  content: "| ";
}

.esv-text small.audio {
  color: #284f57;
}

.esv-text small.audio:hover {
  color: #849ea2;
}

.esv-text span.chapter_audio {
  display: none;
}

.esv-text h2.small {
  color: #393939;
  text-transform: uppercase;
  float: left;
  background-color: #fff;
  border: none;
  margin: -29px 0 0;
  padding: 7px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10.5px;
  font-weight: bold;
  display: inline;
}

.esv-text div.footnotes, .esv-text ul {
  border: 1px solid #c7c5c5;
  -moz-border-radius: 3px;
  margin: 20px 0;
  padding: 10px 0 10px 25px;
}

.esv-text .footnotes h3 {
  color: #b36c38;
  text-align: left;
  font-family: Georgia, Times New Roman, Times, serif;
  font-size: .9em;
  font-style: italic;
  font-weight: 300;
}

.interlinear p {
  text-indent: 0;
}

.interlinear ol li {
  text-align: center;
  text-indent: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

.grc {
  font-family: GalatiaSILRegular;
  font-size: 125%;
}

.interlinear .wi {
  display: none;
}

.interlinear .gl, .interlinear .gm {
  font-family: GalatiaSILRegular;
}

.interlinear .tf, .interlinear .tm {
  font-family: arial, helvetica, verdana, sans-serif;
  font-style: italic;
}

.interlinear ol {
  vertical-align: top;
  margin: 0;
  padding: 0 5px 20px;
  display: inline-block;
}

.interlinear p, .interlinear h3 {
  clear: both;
}

.interlinear span.sub {
  vertical-align: bottom;
  font-size: xx-small;
}

.interlinear .cf, .interlinear .verse-num {
  display: inline-block;
}

#interlinear {
  padding: 0 15px 15px;
}

#interlinear_tab h3 {
  font-size: 1.2em;
  font-weight: bold;
}

#interlinear_tab li {
  list-style: none;
}

.interlinear .flash {
  background-color: #ffe6d2;
}

#concordance .concordance_results {
  margin: 0;
  padding: 0;
}

#concordance .concordance_results li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:bg-primary-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.hover\:bg-primary-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.hover\:bg-primary-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-primary-400:hover {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.hover\:text-primary-800:hover {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.focus\:border-primary-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.focus\:bg-primary-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.focus\:bg-primary-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.focus\:text-primary-500:focus {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.focus\:shadow-\[0_0_0_5px\]:focus {
  --tw-shadow: 0 0 0 5px;
  --tw-shadow-colored: 0 0 0 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-black\/50:focus {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

.focus\:shadow-primary-500:focus {
  --tw-shadow-color: #84cc16;
  --tw-shadow: var(--tw-shadow-colored);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-primary-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity));
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.enabled\:cursor-pointer:enabled {
  cursor: pointer;
}

.enabled\:hover\:bg-gray-50:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-primary-200:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-primary-600:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-red-600:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:border-gray-200:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.disabled\:bg-gray-50:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.disabled\:text-gray-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.disabled\:opacity-70:disabled {
  opacity: .7;
}

.data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none;
}

.data-\[highlighted\]\:bg-primary-500[data-highlighted] {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.data-\[disabled\]\:text-gray-400[data-disabled] {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.data-\[highlighted\]\:text-primary-50[data-highlighted] {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

.data-\[placeholder\]\:text-primary-300[data-placeholder] {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

.data-\[highlighted\]\:outline-none[data-highlighted] {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:focus\:ring-offset-gray-900:focus) {
  --tw-ring-offset-color: #111827;
}

@media (width >= 640px) {
  .sm\:\!bottom-full {
    bottom: 100% !important;
  }

  .sm\:\!top-32 {
    top: 8rem !important;
  }

  .sm\:top-0 {
    top: 0;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:w-12 {
    width: 3rem;
  }

  .sm\:grow-0 {
    flex-grow: 0;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 768px) {
  .md\:bottom-\[unset\] {
    bottom: unset;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
/*# sourceMappingURL=index.b9abb640.css.map */
